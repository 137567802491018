import { UserStatus } from './constants'
import { PermissionSet, getPermissionSetById } from './permissionSet'

export const applyCustomRoleAndStatusNames = <T extends { rolePermId: string; label?: string; state?: string }>(
  permissionSet: PermissionSet,
  users: Array<T>
) => {
  return users.map((user) => {
    const permSet = permissionSet.find((ps) => ps.rolePermId === user.rolePermId)
    const roleName = permSet ? permSet.value : user.rolePermId
    const roleLabel = permSet ? permSet.label : user.rolePermId
    const customState = UserStatus.find((s) => s.value === user.state)
    const stateLabel = customState ? customState.label : user.state

    return {
      ...user,
      roleName,
      roleLabel,
      stateLabel
    }
  })
}

export const getCountByRole = (permissionSet: PermissionSet, items) =>
  items.reduce(
    ({ countAdmin, countUser }, item) => {
      const ps = getPermissionSetById(permissionSet, item.rolePermId)
      return {
        countAdmin: ps?.type === 'admin' ? countAdmin + 1 : countAdmin,
        countUser: ps?.type === 'user' ? countUser + 1 : countUser
      }
    },
    { countAdmin: 0, countUser: 0 }
  )
